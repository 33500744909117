<template>
  <div class="user-list" v-loading="loading">
    <!--<div class="ul title">-->
    <!--  <ul class="box">-->
    <!--    <li class="list">排名</li>-->
    <!--    <li class="list">姓名</li>-->
    <!--    <li class="list" v-if="category === 2">班级</li>-->
    <!--    <li class="list sort-btn" @click="sort('activeRate')">-->
    <!--      <div>完成度</div>-->
    <!--      <div :class="['icon', sortBy !== 'activeRate' ? '' : sortType === 'desc' ? 'icon1' : 'icon2']"></div>-->
    <!--    </li>-->
    <!--    <li class="list sort-btn" @click="sort('scoreRate')">-->
    <!--      <div>得分</div>-->
    <!--      <div :class="['icon', sortBy !== 'scoreRate' ? '' : sortType === 'desc' ? 'icon1' : 'icon2']"></div>-->
    <!--    </li>-->
    <!--    <li class="list">操作/查看</li>-->
    <!--  </ul>-->
    <!--</div>-->
    <!--<div class="ul" v-for="(item, index) in list" :key="item.id">-->
    <!--  <ul class="box">-->
    <!--    <li class="list">-->
    <!--      <div>{{index + 1}}</div>-->
    <!--      <div class="expire-tips" v-if="item.expire === '1'">该学生学习卡已过期</div>-->
    <!--    </li>-->
    <!--    <li class="list">{{item.realName}}</li>-->
    <!--    <li class="list" v-if="category === 2">{{item.className}}</li>-->
    <!--    <li class="list">{{item.activeRate}}%</li>-->
    <!--    <li :class="['list', item.scoreLevel > 0.85 ? 'col-1DC060' : item.scoreLevel > 0.6 ? 'col-fed942' : 'col-FF3C30']">-->
    <!--      {{item.scoreRate}}分-->
    <!--    </li>-->
    <!--    <li class="list btn-list">-->
    <!--      <el-popover class="flex" trigger="manual" v-model="item.popoverShow">-->
    <!--        <div class="btn-box">-->
    <!--          <div class="btn" @click="backHomework(index)" v-if="item.activeRate && item.homeworkState !== '1'">打回重做-->
    <!--          </div>-->
    <!--          <div class="btn" @click="review(index)">{{category === 2 ? '考试点评' : '作业点评'}}</div>-->
    <!--          <div class="btn" @click="homeworkUrge(index)" v-if="item.activeRate !== 100">{{category === 2 ? '催交考试' :-->
    <!--              '催交作业'}}-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="handle" slot="reference" @click.stop="popoverSwitch(index)">-->
    <!--          <div :class="[item.homeworkState === '1' ? 'col-999' : item.homeworkState === '2' ? 'theme-color' : '']">-->
    <!--            {{item.homeworkState === '1' ? '已打回' : item.homeworkState === '2' ? '已重做' : '操作'}}-->
    <!--          </div>-->
    <!--          <div class="icon"></div>-->
    <!--        </div>-->
    <!--      </el-popover>-->
    <!--      <el-popover width="375" class="flex" trigger="manual" v-model="item.answerShow" v-if="passageType !== '2'">-->
    <!--        <div class="answer">-->
    <!--          <div class="answer-list" v-for="(answer, idx) in item.answerData" :key="idx">-->
    <!--            <div>{{answer.questionsTypeName || answer.name || answer.wordName}}</div>-->
    <!--            <div class="score">{{answer.score}}</div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="check-btn" slot="reference" @click.stop="answerSwitch(index)">-->
    <!--          <div>查看</div>-->
    <!--          <div class="icon"></div>-->
    <!--        </div>-->
    <!--      </el-popover>-->
    <!--    </li>-->
    <!--  </ul>-->
    <!--</div>-->

    <el-table ref="tableRef" :data="list" key="id" border max-height="500px">
      <el-table-column label="排名" align="center" width="80" fixed>
        <template slot-scope="{ $index }">{{ (pageIndex - 1) * pageSize + $index + 1 }}</template>
      </el-table-column>
      <el-table-column label="学生姓名" prop="realName" align="center" width="100" fixed>
        <template v-slot="{ row }">{{ row.realName || '-' }}</template>
      </el-table-column>
      <el-table-column v-if="category === 2" label="班级" prop="realName" align="center" fixed>
        <template v-slot="{ row }">{{ row.className || '-' }}</template>
      </el-table-column>
      <el-table-column label="完成度" prop="finishRate" align="center" fixed>
        <template v-slot="{ row }">{{ row.finishRate }}%</template>
      </el-table-column>
      <el-table-column label="总成绩" align="center" fixed>
        <template v-slot="{ row }">{{ row.score }}分</template>
      </el-table-column>
      <el-table-column v-for="(questionTypesItem, index) in initQuestionTypes" :key="index"
                       :label="questionTypesItem.questionTypeName" align="center">
        <el-table-column v-for="(item, i) in questionTypesItem.itemList" :key="`${index}_${i}`" :label="item.itemName"
                         :prop="item.key" align="center">
          <template v-slot="{ row }">
            {{ Array.isArray(row.questionTypeList) ? showItemScore(row, index, item.key) : '-' }}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="操作" align="center" width="200" fixed="right">
        <template v-slot="{ row, $index }">
          <el-button v-if="row.finishRate" class="btn" type="text" size="medium" @click="review($index)">
            {{ category === 2 ? '考试点评' : '作业点评' }}
          </el-button>
          <el-button v-if="row.finishRate && row.homeworkState !== '1'" class="btn col-FF3C30" type="text" size="medium" @click="backHomework($index)" >
            打回重做
          </el-button>
          <el-button class="btn" type="text" size="medium" @click="homeworkUrge($index)" v-if="row.finishRate !== 100">
            {{ category === 2 ? '催交考试' : '催交作业' }}
          </el-button>
        </template>
      </el-table-column>

    </el-table>

    <Dialog
        :dialogType="1"
        :name="dialogName"
        :id="dialogId"
        :list="dialogList"
        :visible="dialogVisible"
        @handleClose="handleClose"
        @addReview="addReview"
    ></Dialog>
  </div>
</template>

<script>
import Dialog from './Dialog2.vue'
import {urgeHomework} from "@/api/homework";

export default {
  name: 'UserList',
  components: {
    Dialog
  },
  props: ['itemId', 'paperGroupId', 'category', 'status', 'statusName', 'flag', 'list', 'sortType', 'sortBy', 'passageType', 'homeworkItemName', 'initQuestionTypes', 'pageIndex', 'pageSize'],
  data() {
    return {
      loading: false,
      dialogId: '',
      dialogName: '',
      dialogVisible: false,
      dialogList: []
    }
  },
  watch: {
    list() {
      this.$refs.tableRef.bodyWrapper.scrollTop = 0
      this.$refs.tableRef.bodyWrapper.scrollLeft = 0
    }
  },
  methods: {
    showItemScore(row, index, key) {
      if (!Array.isArray(row.questionTypeList)) return '-'
      const questionTypeItem = row.questionTypeList[index]
      if (!questionTypeItem) return '-'
      const item = questionTypeItem.itemList.find(item => item.key === key)
      return item ? item.itemScore : '-'
    },
    homeworkUrge(index) {
      if (this.status === '2' || this.statusName === '已结束') {
        this.$alert('作业已过期，请先修改作业截止时间，再进行打回操作。', '提示', {
          confirmButtonText: '好的'
        })
        return
      }
      this.loading = true
      const {list} = this
      console.log(list)
      const {id: studentId, itemId} = list[index]

      const params = {
        studentId,
        category: this.category,
        homeworkItemId: itemId,
        homeworkItemName: this.homeworkItemName
      }
      urgeHomework(params)
          .then((response) => {
            this.loading = false
            if (response.state === '11') {
              this.$set(this.list[index], 'popoverShow', false)
              this.$message({
                message: '催交成功',
                type: 'success'
              })
            }
          })
          .catch(() => {
            this.loading = false
          })
    },
    handleClose() {
      this.dialogVisible = false
    },
    review(index) {
      console.log('review', index, this.list[index])
      const {list} = this
      const name = list[index].realName || list[index].name
      this.dialogName = name
      const {id} = list[index]
      this.dialogId = id
      const itemId = this.itemId || list[index].itemId
      this.reviewList(id, itemId)
    },
    reviewList(userId, itemId) {
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.reviewList,
        data: {
          itemId,
          userId,
          pageIndex: 1,
          pageSize: 10
        }
      }).then((response) => {
        console.log('response', response)
        this.loading = false
        if (response.state === '11') {
          this.dialogList = response.data
          this.dialogItemId = itemId
          this.dialogVisible = true
        }
      }).catch(() => {
        this.loading = false
      })
    },
    addReview(id, content) {
      this.loading = true
      const itemId = this.itemId || this.dialogItemId
      this.$axios({
        method: 'post',
        url: this.$urls.addReview,
        data: {
          itemId,
          userId: id,
          content
        }
      }).then((response) => {
        this.loading = false
        if (response.state === '11') {
          this.reviewList(id, itemId)
        }
      }).catch(() => {
        this.loading = false
      })
    },
    backHomework(index) {
      const {list} = this
      const {realName} = list[index]
      this.$confirm(`确认打回${realName}的考试？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.confirmBack(index)
      })
    },
    confirmBack(index) {
      this.loading = true
      const {list} = this
      const {id: userId} = list[index]
      const itemId = this.itemId || list[index].itemId
      this.$axios({
        method: 'post',
        url: this.$urls.backHomework,
        data: {
          itemId,
          userId
        }
      }).then((response) => {
        this.loading = false
        if (response.state === '11') {
          this.$set(this.list[index], 'homeworkState', '1')
          this.$set(this.list[index], 'popoverShow', false)
        }
      }).catch(() => {
        this.loading = false
      })
    },
    popoverSwitch(index) {
      this.list[index].popoverShow = !this.list[index].popoverShow
      // this.$refs[`popover${index}`].visibility = !this.$refs[`popover${index}`].visibility
    },
    answerSwitch(index) {
      const {list} = this
      const {answerShow} = list[index]
      const {answerData} = list[index]
      for (let i = 0, len = list.length; i < len; i += 1) {
        if (i !== index) {
          list[i].answerShow = false
        }
      }
      if (!answerData) {
        this.answerDetail(index)
        return
      }
      list[index].answerShow = !answerShow
    },
    answerDetail(index) {
      this.loading = true
      const {list} = this
      const {id: userId} = list[index]
      const {flag} = this
      const itemId = this.itemId || list[index].itemId
      let url
      const dataParam = {}
      dataParam.userId = userId
      if (flag === 3) {
        url = this.$urls.repeatAfterUnitById
        dataParam.itemId = itemId
        dataParam.id = this.paperGroupId
      } else if (this.category === 2) {
        url = this.$urls.homeworkExamAnswer
        dataParam.itemId = itemId
        dataParam.flag = flag
      } else if (this.itemId) {
        url = this.$urls.answerDetail
        dataParam.homeworkItemId = itemId
        dataParam.flag = flag
      }
      this.$axios({
        method: 'post',
        url,
        data: dataParam
      }).then((response) => {
        this.loading = false
        if (response.state === '11') {
          const {data} = response
          if (data) {
            if (this.category === 2) {
              for (let i = 0, len = data.length; i < len; i += 1) {
                data[i].name = data[i].shortName
              }
            }
            this.$set(this.list[index], 'answerData', data)
            this.$set(this.list[index], 'answerShow', true)
          }
        }
      }).catch(() => {
        this.loading = false
      })
    },
    sort(name) {
      this.$emit('sort', name)
    }
  }
}
</script>

<style>

</style>

<style lang="scss" scoped>
.el-table--group, .el-table--border {
  border: 1px solid #EBEEF5;
  border-right: none;
  border-bottom: none;
}

.user-list {
  padding: 0 28px 10px;
}

.ul {
  font-size: 15px;
  padding: 0 28px;

  &:hover {
    background-color: #F6F6F6;
  }

  .box {
    @include flex;
    height: 50px;
    border-bottom: 1px solid #F1F1F1;
    overflow: hidden;
  }

  .list {
    flex: 1 1 20%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;

    .expire-tips {
      @include font(14px, #FF3C30, center);
    }
  }

  .sort-btn {
    @include flex;
    cursor: pointer;
    line-height: 1;

    .icon {
      @include icon(url("../../../assets/sort.png"), 6px, 11px);
      margin-left: 6px;
    }

    .icon1 {
      background-image: url("../../../assets/sort-1.png");
    }

    .icon2 {
      background-image: url("../../../assets/sort-2.png");
    }
  }


}

.btn-list {
  @include flex(space-between);

  .flex {
    flex: 1 1 50%;
    @include flex;
    cursor: pointer;

    .icon {
      @include icon(url("../../../assets/icon-1.png"), 6px, 6px);
      margin-left: 4px;
      margin-top: 12px;
    }
  }

  .handle {
    @include flex;
  }

  .check-btn {
    @include flex;

    .icon {
      @include icon(url("../../../assets/spread-2.png"), 7px, 4px);
      margin-left: 6px;
      margin-top: 0;
    }
  }
}

.title {
  color: #999;

  &:hover {
    background-color: transparent;
  }
}

.btn-box {
  .btn {
    height: 41px;
    line-height: 41px;
    @include font(15px, #333, center);
    cursor: pointer;

    &:hover {
      background-color: #F6F6F6;
    }
  }
}

.answer {
  @include font(15px, #333);

  .answer-list {
    min-height: 41px;
    @include flex(space-between);

    .score {
      padding-left: 10px;
      white-space: nowrap;
    }
  }
}
</style>
