<template>
  <div class="flex">
    <div class="left">
      <el-select class="search-grade" :value="classId" @change="classChange" v-if="superAdmin === '2'">
        <el-option label="全部班级" value=""></el-option>
        <el-option v-for="(item, index) in classInfoList" :key="index" :label="item.className" :value="item.classId"></el-option>
      </el-select>
      <el-select class="search-year" :value="groupId" @change="examChange" v-if="menuIndex !== 0">
        <!--<el-option label="全部试题" value=""></el-option>-->
        <el-option v-for="item in examInfoList" :key="item.groupId" :label="item.groupName" :value="item.groupId"></el-option>
      </el-select>
    </div>
    <div class="right">
      <div class="excel-btn" @click="exportExcel">
        <div class="icon"></div>
        <div>{{classIdVal ? '导出当前成绩' : '导出全部成绩'}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Nav',
  props: ['classInfoList', 'examInfoList', 'classId', 'groupId', 'superAdmin', 'menuIndex'],
  data () {
    return {
      classIdVal: ''
    }
  },
  methods: {
    classChange (val) {
      this.classIdVal = val
      this.$emit('classChange', val)
    },
    examChange (val) {
      this.$emit('examChange', val)
    },
    exportExcel () {
      this.$emit('exportExcel')
    }
  }
}
</script>

<style scoped lang="scss">
  .flex {
    @include flex(space-between);
    height: 64px;
    padding: 0 28px;
  }
  .excel-btn {
    @include flex;
    height: 34px;
    padding: 0 20px;
    border: 1px solid #E5E5E5;
    border-radius: 17px;
    font-size: 15px;
    cursor: pointer;
    &:hover {
       background-color: #F6F6F6;
     }
    .icon {
      @include icon(url("../../../assets/excel.png"), 26px, 26px);
      margin-right: 6px;
    }
  }
</style>
